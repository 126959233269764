<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Usuário:" v-model="registro.usuario" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="* Grupo:" :items="selectGrupo" v-model="registro.id_grupo" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_usuario"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Email:" v-model="registro.email" type="email" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Valor hora aula:" v-model="registro.aula_valor" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_usuario"><v-text-field autocomplete="new-password" hide-details label="* Senha:" type="password" v-model="registro.nsenha" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_usuario"><v-text-field hide-details label="* Repita a senha:" type="password" v-model="registro.rsenha" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="* Ativo:" :items="[{value : 't', text : 'Sim'}, {value : 'f', text : 'Não'}]" v-model="registro.ativo" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

export default {
    name: "UsuarioForm",
    components: {SelectFranquia},
    props : ['id_usuario'],
    data() {
        return {
            title: '',
            registro : {},
            erro : null,

            selectGrupo : [],
            selectCidade : []
        }
    },
    computed : {
        ...mapState(['baseUrl', 'usuarioSessao'])
    },
    methods : {
        getUsuario() {
            axios.post(`${this.baseUrl}usuario/get`, {id_usuario : this.id_usuario}).then((res) => {
                if(res.data.erro) {
                    this.$router.push({path : '/usuario'})
                }
                this.registro = {
                    ...res.data,
                    aula_valor : this.numero(res.data.aula_valor)
                }
            })
        },
        selectGrupoListar() {
            axios.post(`${this.baseUrl}usuario/grupo_listar`, {}).then((res) => {
                this.selectGrupo = res.data.lista.filter((v) => {
                    return v.id_grupo != '1' && v.id_grupo != '5'
                }).map((e) => {return {
                    value : e.id_grupo,
                    text : e.grupo
                }})
            })
        },
        salvar() {
            if(!this.id_usuario) {
                axios.post(`${this.baseUrl}usuario/cadastrar`, {...this.registro, aula_valor : this.number(this.registro.aula_valor)}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/usuario/gerenciar/' + res.data.id_usuario})
                    }
                })
            } else {
                axios.post(`${this.baseUrl}usuario/alterar`, {...this.registro, aula_valor : this.number(this.registro.aula_valor), id_usuario : this.id_usuario}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/usuario/gerenciar/' + this.id_usuario})
                    }
                })
            }
        },
        async init() {
            this.registro = {
                ativo : 't'
            }
            await this.selectGrupoListar()
            if(this.id_usuario) {
                await this.getUsuario()
            }
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
